/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import "./App.css";
import { Navbar, Nav } from "react-bootstrap";

function Privacy(props) {


  function Thenav() {
    return (
      <Navbar expand="lg" className="fullnav ">
        <>
          <Navbar.Brand href="/" >
          hostforgen
     
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav" className="mynav text-dark ">
         
            <Nav className="mx-auto text-dark cc ">
      

              <Nav.Link className=" text-light   " href="/usage">
            Usage
              </Nav.Link>

            
        
            </Nav>
          </Navbar.Collapse>
        </>
      </Navbar>
    );
  }

 
  return (
    <div className="otb">
      <Thenav />
      <div className="row content text-center text-light  mx-auto w-75 mt-4">
        <h3 className="mx-auto ">
      
        Usage Agreement
        </h3>
      We appreciate your interest and your concerns about the privacy of your data on the Internet.
        <br />
       This policy has been prepared to assist you in understanding the nature of the data that we do
           They are collected by you when you visit our website and how we deal with it
           This is personal data.
        <br />
        <br />
        <b>Browsing</b>
        <br />
        We did not design this website to collect your personal data from a device
           Your computer while browsing this site, it will only be used
           The data provided by you with your knowledge and of your own free will.
        <br />
        <br />
        <b>
      
         Internet Protocol (IP) address
        </b>
        <br />
        &nbsp;
       Anytime you visit any website, including this website, it will
           The host server records the IP address & nbsp; Private (IP)
           Your date and time of the visit, the type of internet browser you are using and the URL
           For any of the Internet sites that refer you to this site
           On the web.
        <br />
        <b>
          <br />
Network scans
          <br />
        </b>
        The surveys we carry out directly on the network enable us to collect data
           Specific information such as the data required of you regarding your look and feel towards our site
           Your responses are of the utmost importance, and we are appreciated as they enable us to improve your level
           Our website, and you have complete freedom and choice to provide data related to your name
           And other data.
        <div className="container">
          <div className="">
            <div className="">
              <hr className="divider my-4" />
              <p className="text-light mb-5">
              Ready to start your next project with us? Give us a call or send
                us an email and we will get back to you as soon as possible!
              </p>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-lg-4 text-center mb-5 mb-lg-0"></div>
            <div className="col-lg-4 mr-auto text-center">
              <a className="d-block" href="mailto:hostforgen@gmail.com">
              hostforgen@gmail.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
