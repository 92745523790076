/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { Navbar, Nav  } from "react-bootstrap";
import "./App.css";

function Usage(props) {

 

  function Thenav() {
    return (
      <Navbar expand="lg" className=" fullnav ">
        <>
          <Navbar.Brand href="/" >
          hostforgen
      
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav" className="mynav text-dark ">
       
            <Nav className="mx-auto text-dark cc ">
      

              <Nav.Link className=" text-light  " href="/privacy_policy">
              Privacy Policy
              </Nav.Link>

          
            </Nav>
          </Navbar.Collapse>
        </>
      </Navbar>
    );
  }
  return (
    <div className="otb" style={{ minHeight: "100vh" }}>
      <Thenav />
      <div className=" text-center text-light mx-auto w-75 mt-4">
        <h4>
      
        Usage Agreement
        </h4>
        <br />

        <p>
       
          
       By using this blog, by any means, you acknowledge that you have read this The agreement, you understand it, and that you agree to what is stated in it.
          <br />
          The blog provides the ability to comment on the content posted on it. You are entitled as per each
          Users participate in the comment, provided you do not post any content that could
          Be harmful, unlawful, defamatory, infringing, abusive, incitement, dirty, harassing or whatever
          The like. You are solely responsible for the comments you share. Property rights
          Intellectual comments remain registered to you. But the blog owner has the right to reuse
          Comments on any blog related activity without requiring prior permission from
          The author of the comment, provided that he mentions the name of the commenter and does not amend the comment
          In a way that leads to distortion in its meaning. For comments on the blog, it is preferred to mention your name
          Profile. You can use an alias, but it is never allowed to impersonate anyone
          Or a body. Your real email is preferred when commenting. The blog is committed
          Not to use this mail for any activity other than personal correspondence. It is committed to not
          Your mail is delivered to any third party without your prior permission. You must not alter or adapt
          The blog does not use it illegally or in any other way that could harm or
          Blog crashes. The blog collects some of your data, such as type
          Browser, OS, IP number ... etc. The goal of collecting this data is to improve
          The level of service. The blog has the right to share this data with a third party without you
          By linking this data to your personal identity. This Agreement may change from
          From time to time, as I see fit for the purpose of the blog, so I ask you to review it
          And adhere to it.
          <br />
          <br />
          <b>
       
           Evacuation responsibilaty
          </b>
          <br />
       Posting advertisements for products or services and sponsored links does not mean that I recommend them,
          I do not make any guarantees of quality about it. I offer reviews of the blog sites services
          And institutions. These reviews may be paid, some may be free
          The other one might use affiliate links that allow me to collect a certain commission in case
          Your subscription or purchase from this link. I am making these reviews based on
          Information I get from the marketing managers of these sites and organizations, or from
          Private experience or through public information, and from it I do not give you any special guarantees
          Regarding the quality of products or services. I also introduce posts bearing in its content Serra
          Subjective to people, based on correspondence or interviews that take place between us, and despite
          I will see the certificates and documents that show the level and competencies, but I will not
          I guarantee their authenticity 100%, and from it, any errors are the responsibility of their owners. Not bear
          Responsibility for damages that may result from failure to properly implement some of the explanations
        </p>
      </div>
    </div>
  );
}

export default Usage;
