import React, { useState, useEffect } from "react";
import { Form, Button,Navbar, Nav  } from "react-bootstrap";
import "./App.css";
function Thenav() {
    return (
      <Navbar expand="lg" className="fullnav ">
        <>
          <Navbar.Brand href="/" >
          hostforgen
        
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav" className="mynav text-dark ">
            <Nav className="mx-auto text-dark cc ">
              <Nav.Link className=" text-light  " href="/usage">
              
              Usage
              </Nav.Link>
           
              <Nav.Link className=" text-light  " href="/privacy_policy">
              
               Privacy Policy
              </Nav.Link>
          
            </Nav>
          </Navbar.Collapse>
        </>
      </Navbar>
    );
  }
const VPSPlans = () => {
  const plans = [
    {
      id: 1,
      name: "Basic Plan",
      price: "EURO10/month",
      features: ["1 vCPU", "1 GB RAM", "20 GB SSD", "1 TB Bandwidth"],
    },
    {
      id: 2,
      name: "Standard Plan",
      price: "EURO20/month",
      features: ["2 vCPU", "4 GB RAM", "40 GB SSD", "2 TB Bandwidth"],
    },
    {
      id: 3,
      name: "Premium Plan",
      price: "EURO40/month",
      features: ["4 vCPU", "8 GB RAM", "80 GB SSD", "5 TB Bandwidth"],
    },
    {
      id: 4,
      name: "Enterprise Plan",
      price: "EURO80/month",
      features: ["8 vCPU", "16 GB RAM", "160 GB SSD", "10 TB Bandwidth"],
    },
  ];

  return (
    <div style={styles.container}>
    <Thenav/>
      <h1 style={styles.title}>Choose Your VPS Plan</h1>
      <div style={styles.plansContainer}>
        {plans.map((plan) => (
          <div key={plan.id} style={styles.planCard}>
            <h2 style={styles.planName}>{plan.name}</h2>
            <p style={styles.planPrice}>{plan.price}</p>
            <ul style={styles.featuresList}>
              {plan.features.map((feature, index) => (
                <li key={index} style={styles.featureItem}>{feature}</li>
              ))}
            </ul>
            <Button style={styles.selectButton}>
            
            <a className="d-block text-light" href="mailto:hostforgen@gmail.com">
            Select Plan
              </a></Button>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  title: {
    fontSize: '2rem',
    marginBottom: '20px',
  },
  plansContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
    flexWrap: 'wrap',
  },
  planCard: {
    border: '1px solid #ddd',
    borderRadius: '10px',
    padding: '20px',
    width: '250px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.2s ease',
  },
  planName: {
    fontSize: '1.5rem',
    marginBottom: '10px',
  },
  planPrice: {
    fontSize: '1.25rem',
    marginBottom: '15px',
    color: '#2c3e50',
  },
  featuresList: {
    listStyleType: 'none',
    padding: 0,
    marginBottom: '15px',
  },
  featureItem: {
    fontSize: '1rem',
    margin: '5px 0',
  },
  selectButton: {
    padding: '10px 20px',
    fontSize: '1rem',
    backgroundColor: '#3498db',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default VPSPlans;
