import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Form, Button,Navbar, Nav  } from "react-bootstrap";
import "./App.css";
import { useSearchParams } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Privacy from "./privacy_policy";
import Usage from "./usage";
import VPSPlans from "./plans";


const stripePromise = loadStripe("pk_live_51QL6CEFyr1e8fvN1ZSkAmLJWyyMfAFRdECCedHj2Vu7FxCMQ7JxMidm7L98MYolFv1rA9PaPM5a8vnAdS1ywjidA00fmZ827PV"); // Replace with your actual key

function App(props) {
    const { showscreanshotanddetails } = props;
    const Lang = 3;
    const [showpaypal, setshowpaypal] = useState(false);
    const [searchParams] = useSearchParams();

    const [price, setPrice] = useState(searchParams.get('price')); // Dynamic price state
    const [username, setusername] = useState(searchParams.get('username')); // Dynamic price state

    const [email, setemail] = useState(searchParams.get('email')); // Dynamic price state

    const [plan, setplan] = useState(searchParams.get('plan')); // Dynamic price state


    useEffect(() => {
        
        if (username && email && plan && Number(price)) {
            

        }
    }, [price,username,plan,email]);
    const handleCheckout = async () => {
        if (username && email && plan && Number(price)) {
            try {
                const stripe = await stripePromise;
      
                if (!stripe) {
                    console.error("Stripe has not been initialized correctly.");
                    return;
                }
      
                // Fetch the Checkout Session ID from the backend
                const response = await fetch(
                    "https://vpngenerators.com:3100/create-payment-intent",
                    {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({ 
                          unit_amount: Math.round(price * 100), // Pass dynamic unit_amount
                          username :username ,           // Include username
                          email  :email,
                          plan : plan             // Include email
                      }),
                    }
                );
      
                const data = await response.json();
      
                if (data && data.id) {
                    // Redirect to Stripe Checkout
                    const { error } = await stripe.redirectToCheckout({
                        sessionId: data.id, // Use the session ID returned by the backend
                    });
      
                    if (error) {
                        console.error("Stripe Checkout error:", error.message);
                    }
                } else {
                    console.error("Invalid response from backend:", data);
                }
            } catch (error) {
                console.error("Error during checkout:", error.message);
            }
        }
   else return
  };
  function Thenav() {
    return (
      <Navbar expand="lg" className="fullnav ">
        <>
          <Navbar.Brand href="/" >
          hostforgen
        
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav" className="mynav text-dark ">
            <Nav className="mx-auto text-dark cc ">
              <Nav.Link className=" text-light  " href="/usage">
              
              Usage
              </Nav.Link>
           
              <Nav.Link className=" text-light  " href="/privacy_policy">
              
               Privacy Policy
              </Nav.Link>
              <Nav.Link className=" text-light  " href="/plans">
              
              Hosting plans
             </Nav.Link>
              
            </Nav>
          </Navbar.Collapse>
        </>
      </Navbar>
    );
  }
    const Application = () => (
        <div className="setup">
        <Thenav/>
        <Form className="form_application setupdiv">
            <div className="subscription-setup-card">
                <div className="text-center mb-2">
                    <div className="subscription-setup">
                        <h2 className="subscription-setup-title">
                            {Lang === 1
                                ? "إعداد الحساب الخاص بك"
                                : Lang === 2
                                ? "Configuration de l'abonnement"
                                : "Subscription setup"}
                        </h2>
                    </div>
                
                </div>
<div style={{
    backgroundColor: "#f4f4f4", 
    padding: "20px", 
    borderRadius: "8px", 
    fontFamily: "Arial, sans-serif", 
    color: "#333", 
    textAlign: "center", 
    maxWidth: "600px", 
    margin: "20px auto", 
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)"
}}>
  <h2 style={{ color: "#0078D4" }}>Welcome to Our Hosting Store!</h2>
  <p>
    At <strong>hostforgen</strong>, we provide reliable and affordable hosting solutions for 
    individuals and businesses. Whether you need shared hosting, VPS, or a dedicated server, 
    we’ve got you covered with top-notch performance and 24/7 customer support.
  </p>
  <p>
    Take your online presence to the next level with our customizable plans, 
    starting at just <strong>5 euro a month</strong>. Join thousands of satisfied customers 
    and experience hosting that’s fast, secure, and worry-free!
  </p>
 
</div>
 <div className="subtheplanparenttitel">
                            {username ? `username : ${(username )} ` : "somthing wrong..."} 
                        </div>
                        <div className="subtheplanparenttitel">
                            {email ? `email : ${(email )} ` : "somthing wrong..."} 
                        </div>
                   
    <p className="textsetupsmall2">
                        {Lang === 1
                            ? "سيتم تزويدك بجميع المعلومات عبر البريد الإلكتروني بعد الدفع"
                            : Lang === 2
                            ? "Toutes les informations vous seront communiquées par email après paiement"
                            : "You will be provided with all the information by email after payment"}
                    </p>

                    
                <div className="total">
                    <div className="theplanparent d-flex justify-content-between">
                        <strong className="text-dark">
                            {Lang === 1 ? "المنتج" : Lang === 2 ? "Produit" : "Product"}
                        </strong>
                        <div className="text-dark">
                            {Lang === 1 ? "المبلغ المستحق" : Lang === 2 ? "Total" : "Subtotal"}
                        </div>
                    </div>
                    <div className="theplan d-flex justify-content-between">
                        <div className="subtheplanparenttitel"> {plan ? `${(plan )} Subscription ` : "somthing wrong..."} </div>
                        <div className="subtheplanparenttitel">
                            {price ? `${(price )} euro` : "somthing wrong..."} 
                        </div>
                    </div>
                </div>

                <div className="addcart">
                    {!showpaypal ? (
                        <Button
                            className="custom-buy-now-btn m-1 align-item-center"
                            size="lg"
                            onClick={handleCheckout} // Directly trigger checkout
                        >
                            {Lang === 1
                                ? "إدفع الأن"
                                : Lang === 2
                                ? "ACHETER MAINTENANT"
                                : "BUY NOW"}
                        </Button>
                    ) : null}
                </div>
            </div>
        </Form>
        </div>
    );
function Success() {
    return(
        <div className="successdiv">
        <div className="d-grid content text-center text-light  mx-auto  mt-4">
          <h3 className="text-light">
            {Lang === 1
              ? "شكرا لثقتك بنا"
              : Lang === 2
              ? "Merci pour votre confiance en nous"
              : "Thanks for your confidence in us"}
          </h3>
          <br />
          <div className="text-muted">
            {Lang === 1
              ? "سوف نرسل لك بريدًا إلكترونيًا يحتوي على جميع المعلومات لتسجيل الدخول إلى منطقة العميل قريبا   "
              : Lang === 2
              ? "nous vous enverrons un email avec toutes les informations pour vous connecter à l'espace client dans un instant  "
              : "we will send you an email  with all the information to login to client area in a moment to "}
            <br />
            
            <br />
           <strong className="text-danger " > {Lang === 1
              ?  "يرجى التحقق من قسم البريد العشوائي(spam ) أيضًا على بريدك الإلكتروني"
              : Lang === 2
              ? "veuillez également vérifier la section spam sur votre e-mail"
              : "please check spam section also on your email"}</strong>
              <br/>
            
         
        
            <br/>
            <strong className="text-muted " > {Lang === 1
              ?  "إذا لم تتلق المعلومات خلال ساعتين، فيرجى الاتصال بنا "
              : Lang === 2
              ? "si vous n'avez pas reçu l'information dans les 2 heures, veuillez nous contacter "
              : "if you did not receive the information in 2 hours please contact us  "}</strong>
              <br/>
      
          </div>
        </div>
      </div>
    )
}
function Cancel() {
    return(
        <div className="successdiv">
        <div className="d-grid content text-center text-light  mx-auto  mt-4">
          <h3 className="text-light">
            {Lang === 1
              ? "شكرا لثقتك بنا"
              : Lang === 2
              ? "Merci pour votre confiance en nous"
              : "Thanks for your confidence in us"}
          </h3>
          <br />
         somthing went wrong , please contact us if you think it's our end issue 

         <Button onClick={()=>window.location.assign('/')}>Back home page</Button>
        </div>
      </div>
    )
}

    return (
       
        <div
            className="Thecardgatway text-light"
        
        >
     
     
         <Routes>
          <Route
            exact
            path="/success"
            element={
              <Success  />
            }
          />
           <Route
            exact
            path="/cancel"
            element={
              <Cancel  />
            }
          />
           <Route
            exact
            path="/privacy_policy"
            element={
              <Privacy  />
            }
          />

          <Route
            exact
            path="/usage"
            element={
              <Usage />
            }
          />
   <Route
            exact
            path="/plans"
            element={
              <VPSPlans />
            }
          />

    <Route
            exact
            path="/"
            element={
              <Application  />
            }
          />
          
           

          </Routes>
          
        </div>
    );
}

export default App;
