import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter as Router} from "react-router-dom";
import { createRoot } from 'react-dom/client';

import reportWebVitals from './reportWebVitals';
const root = createRoot(document.getElementById('root'));
root.render(

     <Router>
    <App />
    </Router>

);


reportWebVitals();
